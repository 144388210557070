<template>
    <div>
        <div class="zttitle">
            新增发布职位
        </div>
        <el-form :model="params" :rules="rules" ref="ruleForm" label-width="0">
            <div class="zl">
                <div class="qy-item">
                    <p><i>*</i>职位类型</p>
                    <el-form-item label="" prop="recruitPost">
                        <el-select v-model="params.recruitPost" placeholder="请选择职位类型"
                                   @change="(v) => {selectChange2(v,this.industryDictionary, 'recruitJob');getJobListPage(params.recruitPost)}">
                            <el-option
                                    v-for="item in industryDictionary"
                                    :key="item.dictCode"
                                    :label="item.dictValue"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="qy-item">
                    <p><i>*</i>岗位</p>
                    <el-form-item label="" prop="postName">
                        <el-select
                                v-model="params.postName"
                                placeholder="请选择岗位"
                                @change="(v) => selectChange2(v,this.postDictionary, 'jobName')"
                        >
                            <el-option
                                    v-for="item in postDictionary"
                                    :key="item.dictCode"
                                    :label="item.dictValue"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="qy-item">
                    <p><i>*</i>工作类型</p>
                    <el-form-item label="" prop="jobType">
                        <el-select @change="(v) => selectChange(v,'position_type', 'jobTypeName')"
                                   v-model="params.jobType" placeholder="请选择工作类型">
                            <el-option
                                    v-for="(item) in options.position_type"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>

                <div class="qy-item">
                    <p><i>*</i>联系人</p>
                    <el-form-item label="" prop="contactPserson">
                        <el-input v-model="params.contactPserson" placeholder="请输入联系人"></el-input>
                    </el-form-item>
                </div>
                <div class="qy-item">
                    <p><i>*</i>联系方式</p>
                    <el-form-item label="" prop="contactTel">
                        <el-input v-model="params.contactTel" placeholder="请输入联系方式" maxlength="20"></el-input>
                    </el-form-item>
                </div>
                <div class="qy-item">
                    <p><i>*</i>招聘人数</p>
                    <el-form-item label="" prop="recruitCount">
                        <el-input v-model="params.recruitCount" placeholder="请输入招聘人数" maxlength="11"></el-input>
                    </el-form-item>
                </div>

                <div class="qy-item">
                    <p><i>*</i>学历要求</p>
                    <el-form-item label="" prop="education">
                        <el-select @change="(v) => selectChange(v,'educational', 'educationName')"
                                   v-model="params.education" placeholder="请选择">
                            <el-option
                                    v-for="(item) in options.educational"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="qy-item">
                    <p><i>*</i>公司福利</p>
                    <el-form-item label="" prop="welfareName">
                        <el-input
                                v-model="params.welfareName"
                                placeholder="请输入福利，多个福利请用，分割"/>
                    </el-form-item>
                </div>
                <div class="qy-item">
                    <p><i>*</i>薪资范围</p>
                    <el-form-item label="" prop="wageMin">
                        <el-select @change="(v) => selectChange(v,'monthly', 'wageMax')" v-model="params.wageMin"
                                   placeholder="请选择">
                            <el-option
                                    v-for="(item) in options.monthly"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="qy-item clear">
                    <p><i>*</i>工作年限</p>
                    <el-form-item label="" prop="companyExperience">
                        <el-select @change="(v) => selectChange(v,'company_experience', 'companyExperienceName')"
                                   v-model="params.companyExperience" placeholder="请选择">
                            <el-option
                                    v-for="(item) in options.company_experience"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="qy-item gzd">
                    <p><i>*</i>工作地</p>
                    <el-form-item label="" prop="provinCode">
                        <el-select @change="changecity(2)" v-model="params.provinCode" placeholder="请选择">
                            <el-option
                                    v-for="(item) in citys[1]"
                                    :key="item.dictCode"
                                    :label="item.dictValue"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" prop="cityCode">
                        <el-select @change="changecity(3)" v-model="params.cityCode" placeholder="请选择">
                            <el-option
                                    v-for="(item) in citys[2]"
                                    :key="item.dictCode"
                                    :label="item.dictValue"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" prop="areaCode">
                        <el-select @change="changecity(4)" v-model="params.areaCode" placeholder="请选择">
                            <el-option
                                    v-for="(item) in citys[3]"
                                    :key="item.dictCode"
                                    :label="item.dictValue"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="qy-item">
                    <p><i>*</i>详细地址</p>
                    <el-form-item label="" prop="address">
                        <el-input v-model="params.address" placeholder="请输入公司详细地址"></el-input>
                    </el-form-item>
                </div>

                <div class="qy-item">
                    <p><i>*</i>期望到岗日期</p>
                    <el-form-item label="" prop="arrivalTime">
                        <el-select @change="(v) => selectChange(v,'arrival_time', 'arrivalTimeName')"
                                   v-model="params.arrivalTime" placeholder="请选择">
                            <el-option
                                    v-for="item in options.arrival_time"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictCode"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>

                <div class="qy-item clear zwms" style="height: 16rem">
                    <p><i>*</i>职位描述</p>
                    <el-form-item label="" prop="jobRemark">
                        <el-input type="textarea" v-model="params.jobRemark" name="" id="" cols="30"
                                  rows="8"></el-input>
                    </el-form-item>
                </div>
                <div class="qy-item clear zwms">
                    <el-button @click="submitForm('ruleForm')" class="btn" type="primary">发布职位</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import event from '@/utils/event';
    import {getDictType, cityCode, pRecruitAdd, getpRecruit, updatepRecruit} from '@/api/zp'
    import {jobListPage} from "@/api/myResume";

    export default {
        name: 'Release',
        data() {
            return {
                editorOption: {
                    placeholder: "请输入职位描述",
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'],
                            [{'list': 'ordered'}, {'list': 'bullet'}],
                        ]
                    }
                },
                citys: [
                    [],
                    [],
                    [],
                    []
                ],
                options: {},
                params: {
                    recruitPost: '',
                    recruitJob:'',
                    jobName: '',
                    postName: "",
                    jobType: '',
                    contactPserson: '',
                    contactTel: '',
                    recruitCount: '',
                    education: '',
                    educationName: '',
                    welfareName: '',
                    wageMax: '',
                    wageMin: '',
                    address: '',
                    companyExperience: '',
                    jobRemark: '',
                    provinCode: '',
                    cityCode: '',
                    areaCode: '',
                    arrivalTime: '',
                    arrivalTimeName: ''
                },
                industryDictionary: [],
                postDictionary: [],
                rules: {
                    recruitPost: [{required: true, message: '请选择职位类型', trigger: 'change'}],
                    postName: [{required: true, message: '请输入岗位', trigger: 'blur'}],
                    jobType: [{required: true, message: '请选择工作类型', trigger: 'change'}],
                    contactPserson: [{required: true, message: '请输入联系人', trigger: 'blur'}],
                    contactTel: [{required: true, message: '请输入联系方式', trigger: 'blur'},
                        {
                            pattern: /(^(\d{3,4}-)?\d{7,8})$|(^0?1[3456789]\d{9})$/,
                            message: '请输入正确的联系方式',
                            trigger: ['blur', 'change']
                        }],
                    recruitCount: [{required: true, message: '请输入招聘人数', trigger: 'blur'}],
                    education: [{required: true, message: '请选择学历要求', trigger: 'change'}],
                    welfareName: [{required: true, message: '请输入公司福利', trigger: 'change'}],
                    wageMin: [{required: true, message: '请选择薪资范围', trigger: 'change'}],
                    companyExperience: [{required: true, message: '请选择工作经验', trigger: 'change'}],
                    jobRemark: [{required: true, message: '请输入职位描述', trigger: 'blur'}],
                    provinCode: [{required: true, message: '请选择省级城市', trigger: 'change'}],
                    cityCode: [{required: true, message: '请选择市级城市', trigger: 'change'}],
                    areaCode: [{required: true, message: '请选择地区城市', trigger: 'change'}],
                    address: [{required: true, message: '请输入详细地址', trigger: 'blur'}],
                    arrivalTime: [{required: true, message: '请选择期望到岗日期', trigger: 'change'}],
                }
            };
        },
        created() {
            this.getDictTypes('position')
            this.getDictTypes('position_type')
            this.getDictTypes('educational')
            this.getDictTypes('monthly')
            this.getDictTypes('company_experience')
            this.getDictTypes('arrival_time')
            this.getcityCode('', 1)

            // 编辑
            if (this.$route.query.id) {
                getpRecruit(this.$route.query.id).then(res => {
                    this.isEdite = true
                    this.params = res.data
                    jobListPage({parentId: this.params.recruitPost}).then((res) => {
                        this.postDictionary = res.data
                    })
                    this.$nextTick(() => {
                        document.documentElement.scrollTop = 0
                    })
                    this.getcityCode(this.params.provinCode, 2)
                    this.getcityCode(this.params.cityCode, 3)
                })
            }
        },
        mounted() {
            setTimeout(() => {
                this.$refs['ruleForm'].clearValidate();
            })
            this.getJobListPage(0);
        },
        components: {},
        methods: {

            // 获取城市
            getcityCode(code, level) {
                cityCode({
                    code,
                    level
                }).then(res => {
                    this.$set(this.citys, level, res.data)
                })
            },
            // 获取子集
            changecity(level) {
                let code = ''
                if (level == 2) {
                    this.params.cityCode = ''
                    this.params.areaCode = ''
                    code = this.params.provinCode
                    this.citys[level - 1].some(item => {
                        if (code === item.dictCode) {
                            this.params.provinName = item.dictValue
                            return true
                        }
                    })
                    this.getcityCode(code, level)
                } else if (level == 3) {
                    this.params.areaCode = ''
                    code = this.params.cityCode
                    this.citys[level - 1].some(item => {
                        if (code === item.dictCode) {
                            this.params.cityName = item.dictValue
                            return true
                        }
                    })
                    this.getcityCode(code, level)
                } else {
                    this.citys[level - 1].some(item => {
                        if (this.params.areaCode === item.dictCode) {
                            this.params.areaName = item.dictValue
                            return true
                        }
                    })
                }
            },
            // 获取下拉数据源
            getDictTypes(type) {
                getDictType(type).then(res => {
                    this.$set(this.options, type, res.data)
                })
            },

            selectChange(v, type, value) {
                this.options[type].some(item => {
                    if (item.dictCode === v) {
                        this.$set(this.params, value, item.dictLabel)
                        return true
                    }
                })
            },
            selectChange2(v, arr, value) {
                debugger
                arr.some(item => {
                    if (item.dictCode === v) {
                        this.$set(this.params, value, item.dictValue)
                        return true
                    }
                })
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (!this.isEdite) {
                            pRecruitAdd({
                                ...this.params,
                                // welfareName: this.params.welfareName.join(',')
                            }).then(() => {
                                event.$emit('loadCount')
                                this.$message.success('添加成功！')
                                this.$router.replace('/recruitCenter/releaseed')
                            })
                        } else {
                            updatepRecruit({
                                ...this.params,
                                // welfareName: this.params.welfareName.join(',')
                            }).then(() => {
                                this.$message.success('修改成功！')
                                this.$router.replace('/recruitCenter/releaseed')
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            // 获取职业下拉字典
            getJobListPage(id) {
                if (id == 0) {
                    jobListPage({parentId: id}).then((res) => {
                        this.industryDictionary = res.data

                    })
                } else {
                    this.params.postName = null,
                        jobListPage({parentId: id}).then((res) => {
                            this.postDictionary = res.data
                        })
                }
            },
        },
    };
</script>
<style scoped lang="scss">
    .zl {
        /deep/ .el-form-item {
            margin-bottom: 0 !important;
        }

        .gzd {
            .el-form-item {
                display: inline-block;
            }

            .el-form-item:nth-child(3) {
                margin-left: 12px;
                margin-right: 12px;
            }
        }
    }

    .center {
        overflow: hidden;

        .right {
            i {
                font-style: normal;
                color: red;
                margin-right: 3px;
            }

            .zttitle {
                background: #fff;
                font-weight: 400;
                color: #666666;
                margin-bottom: 20px;
                padding: 32px 16px;
                font-size: 22px;
                font-weight: bold;
                color: #333333;
            }

            .zl {
                margin-right: -15px;
                background: #fff;
                overflow: hidden;
                padding: 0 16px 16px;
            }

            .gs {
                span {
                    float: left;
                    margin-right: 16px;
                    margin-top: 26px;
                }

                .custom-upload {
                    /deep/ .el-upload--picture-card {
                        width: 78px;
                        height: 78px;
                        border-radius: 0;

                        .el-icon-zp {
                            display: block;
                            width: 78px;
                            height: 78px;
                            background: transparent url('../../assets/img/recruit/icon-logo.png') no-repeat left top;
                        }
                    }
                }
            }

            .qy-item {
                width: 306px;
                float: left;
                margin-top: 20px;
                margin-right: 15px;

                .el-select,
                .el-date-editor {
                    width: 100%;
                }

                &.gzd {
                    .el-select {
                        width: 94px;

                        &:nth-child(3) {
                            margin: 0 12px;
                        }
                    }
                }

                &.zwms {
                    width: 950px;
                }

                p {
                    margin-bottom: 8px;
                    height: 19px;
                }

                .custom-upload2 {
                    width: 100%;

                    /deep/ .el-upload--picture-card {
                        width: 100%;
                        height: 170px;
                        border: 0;
                        border-radius: 0;
                        background: #eeeeee url(../../assets/img/recruit/icon-sj.png) no-repeat center center;
                    }
                }
            }
        }
    }

    .clear {
        clear: both;
    }

    .btn {
        background: #0780E6;
        border-radius: 0;
        padding: 9px 20px;
    }
</style>
